import LocalizedLink from "./localizedLink";
import React, {useEffect, useState} from "react";
import useTranslations from "../components/useTranslations";

const SearchResult = ({ state }) => {
	const {
		yen,
		taxIncluded,
		searchResults,
		pages,
		casenumber,
	} = useTranslations();

	const products =
		state.queryResults.length && state.queryResults.filter(Boolean);
	const [currentPage, setCurrentPage] = useState(1);
	const [currentPageSp, setCurrentPageSp] = useState(1);

	useEffect(() => {
		setCurrentPage(JSON.parse(window.localStorage.getItem('current-page')));
	}, []);
	useEffect(() => {
		window.localStorage.setItem('current-page', currentPage);
	}, [currentPage]);
	useEffect(() => {
		setCurrentPage(JSON.parse(window.localStorage.getItem('current-page-sp')));
	}, []);
	useEffect(() => {
		window.localStorage.setItem('current-page', currentPageSp);
	}, [currentPageSp]);

	if(currentPage == null){
		setCurrentPage(1);
	}
	if(currentPageSp == null){
		setCurrentPageSp(1);
	}

	const postsPerPage = 40;
	const postsPerPageSp = 10;
	const totalPage = Math.ceil(products.length / postsPerPage);
	const totalPageSp = Math.ceil(products.length / postsPerPageSp);

	const paginate = (pageNumber) => {
		setCurrentPage(pageNumber)
		window.scrollTo(0, 0);
	};

	const paginateSp = (pageNumber) => setCurrentPageSp(pageNumber);

	const indexOfLastPost = currentPage * postsPerPage;
	const indexOfFirstPost = indexOfLastPost - postsPerPage;
	const currentPosts = products.slice(indexOfFirstPost, indexOfLastPost);
	// const currentPosts = products.slice(indexOfFirstPost, indexOfLastPost);


	const indexOfLastPostSp = currentPageSp * postsPerPageSp;
	const indexOfFirstPostSp = indexOfLastPostSp - postsPerPageSp;
	const currentPostsSp = products.slice(indexOfFirstPostSp, indexOfLastPostSp);

	const pathName = typeof window !== 'undefined' ? window.location.pathname : '';

	return (
		<>
			<div className="content-container pc">
				{searchResults} ：{products.length}
				{casenumber}


				{
					(pathName.indexOf('/en') !== -1) ?
						<ul className="product-list flex wrap">
							{products.length &&
							currentPosts.map((item) => {
								return (
									<a
										key={`${item.sku}-${item.productLocale}`}
										className={`product-link`}
										href={`/en/${item.sku}`}
										target={'_blank'}
										rel="noreferrer"
									>
										<li className={`${item.sku}-${item.productLocale}`}>
											<img src={item.productImages} alt=""/>
											<p className="sku">{item.sku}</p>
											<p className="name medium bold">{item.name}</p>
											<p className="name medium bold">{item.model}</p>
										</li>
									</a>
								);
							})}
						</ul>
						:
						<ul className="product-list flex wrap">
							{products.length &&
							currentPosts.map((item) => {
								return (
									<a
										key={`${item.sku}-${item.productLocale}`}
										className={`product-link`}
										href={`/${item.sku}`}
										target={'_blank'}
										rel="noreferrer"
									>
										<li className={`${item.sku}-${item.productLocale}`}>
											<img src={item.productImages} alt=""/>
											<p className="sku">{item.sku}</p>
											<p className="name medium bold">{item.name}</p>
											<p className="name medium bold">{item.model}</p>
											<p className="name medium bold">
												{item.price}
												{yen} <span>{taxIncluded}</span>
											</p>
										</li>
									</a>
								);
							})}
						</ul>
				}
				<div className="pagination-bar flex center">
					<input
						type="button"
						onClick={() =>
							paginate(currentPage > 1 ? currentPage - 1 : currentPage)
						}
						className="prev"
					/>
					<div className="flex-part flex center">
						<p>{pages}：</p>
						<input
							className={"medium page-number"}
							type={"text"}
							pattern="[0-9]*"
							onInput={(e) => paginate(parseInt(e.target.value), 10)}
							value={currentPage}
						/>
						<p>/ {totalPage}</p>
					</div>
					<input
						type="button"
						onClick={() =>
							paginate(currentPage < totalPage ? currentPage + 1 : currentPage)
						}
						className="next"
					/>
				</div>
			</div>

			<div className={"content-container sp"}>
				{searchResults} ：{products.length}
				{casenumber}


				{
					(pathName.indexOf('/en') !== -1) ?
						<ul className="product-list flex wrap">
							{products.length &&
							currentPostsSp.map((item) => {
								return (
									<a
										key={`${item.sku}-${item.productLocale}`}
										className={`product-link`}
										href={`/en/${item.sku}`}
										target={'_blank'}
										rel="noreferrer"
									>
										<li className={`${item.sku}-${item.productLocale}`}>
											<div className="flex column-to-row">
												<img src={item.productImages} alt=""/>
												<div className="txt-box">
													<p className="sku">{item.sku}</p>
													<p className="name medium bold">{item.name}</p>
												</div>
											</div>
										</li>
									</a>
								);
							})}
						</ul>
						:
						<ul className="product-list flex wrap">
							{products.length &&
							currentPostsSp.map((item) => {
								return (
									<a
										key={`${item.sku}-${item.productLocale}`}
										className={`product-link`}
										href={`/${item.sku}`}
										target={'_blank'}
										rel="noreferrer"
									>
										<li className={`${item.sku}-${item.productLocale}`}>
											<div className="flex column-to-row">
												<img src={item.productImages} alt=""/>
												<div className="txt-box">
													<p className="sku">{item.sku}</p>
													<p className="name medium bold">{item.name}</p>
													<p className="name medium bold">
														{item.price}
														{yen}
														<span>{taxIncluded}</span>
													</p>
												</div>
											</div>
										</li>
									</a>
								);
							})}
						</ul>
				}
				<div className="pagination-bar flex center">
					<input
						type="button"
						onClick={() =>
							paginateSp(currentPageSp > 1 ? currentPageSp - 1 : currentPageSp)
						}
						className="prev"
					/>
					<div className="flex-part flex center">
						<p>{pages}：</p>
						<input
							className={"medium page-number"}
							type={"text"}
							pattern="[0-9]*"
							onInput={(e) => paginate(parseInt(e.target.value), 10)}
							value={currentPageSp}
						/>
						<p>/ {totalPageSp}</p>
					</div>
					<input
						type="button"
						onClick={() =>
							paginateSp(
								currentPageSp < totalPageSp ? currentPageSp + 1 : currentPageSp
							)
						}
						className="next"
					/>
				</div>
			</div>
		</>
	);
};

export default SearchResult;
