import React, {useEffect} from "react";
import Footer from "../../components/Footer/Footer";
import CategoryLeftNav from "../../components/TwoColumnLeftNav/CategoryLeftNav";
import SearchResult from "../../components/SearchResult";
import useTranslations from "../../components/useTranslations";

export default ({ location }) => {
	const { ProductNotFound } = useTranslations();

	typeof window !== "undefined" && window.history.pushState(null, null, null);
	typeof window !== "undefined" && window.addEventListener('popstate', (e) => {
		window.scrollTo(0, 0)
		console.log('back')
	});
	// useEffect(() => {
	// 	return () => {
	// 		window.history.pushState(null, null, null);
	// 		// window.history.pushState(null, null, window.location.href);
	// 		window.addEventListener('popstate', (e) => {
	// 			// location.state = {
	// 			// 	queryResults:  localStorage.getItem('queryResults') || []
	// 			// }
	// 			// window.history.pushState(null, null, null);
	// 			window.scrollTo(0, 0)
	// 			console.log('back')
	// 		});
	// 		// window.addEventListener('popstate', function (e) {
	// 		// 		alert('ブラウザでの戻るボタンは禁止されております。');
	// 		// 		window.history.pushState(null, null, null);
	// 		// }, false);
	// 	}}
	// );
	return (
		<>
			<article className="category-page two-column-left-nav">
				<CategoryLeftNav />
				<section className="main-content">
					{(
						location.state !== null &&
						typeof location.state != "undefined" &&
						location.state.queryResults &&
						location.state.queryResults.length > 0 &&
						(
							<SearchResult state={location.state} />
						)) || <h1>{ProductNotFound} </h1>}
				</section>
			</article>
			<Footer />
		</>
	);
};
